import { EnumFilterKey } from '~/shared/api/types';
import { checkIsServer } from '~/shared/browserAPI/isServer';
import { sentryLog } from '~/shared/helpers/sentryLog';
import useAppRouter from '~/shared/hooks/useAppRouter';

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (checkIsServer()) {
    return;
  }

  const router = useAppRouter();

  if (to.meta.isOrderByWkoPage && from.meta.isOrderDetails) {
    router.goToProfileOrders();
    return;
  }

  const { orderSKU, shopId } = to.query;

  if (to.meta.isOrderByWkoPage && (!orderSKU || !shopId)) {
    router.goToProfileOrders();
    return;
  }

  if (orderSKU && shopId) {
    const { $api } = useNuxtApp();

    const query = {
      filter: {
        ['order.number']: {
          [EnumFilterKey.Eq]: orderSKU,
        },
        shopId: {
          [EnumFilterKey.Eq]: shopId,
        },
      },
      limit: 1,
    };

    try {
      const { items } = await $api.personalAccount.getShopOrders({ params: query });

      if (items.length === 0) {
        router.goToProfileOrders();
      } else {
        router.goToProfileOrder(items[0].id, { force: true });
      }
    } catch (error) {
      sentryLog(error as Error);
      router.goToProfileOrders();
    }
  }
});
